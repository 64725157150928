import React from "react"
import Layout from "../components/layout";
import LayoutSimple from "../components/layout-simple";
import styled from "styled-components";
const FormWrapper = styled('div')`
  max-width: 720px;
  label {
    color: black;
  }
`;
import blob from '../blob/blob-shape_blue_a.svg';

const SuccessPage = () => (
    <LayoutSimple location="contact" >
        <main>
            <img className={'blob-top-right'} src={blob} />
            <h1>Contact Kingscote Doodles</h1>
            <p>Having a dog join your family is an important decision. That's why we're always here to answer any questions you have about owning a Doodles and we typically respond within 24 hours.
            </p>
          <p>If you would prefer to email us direct you can do so via <a href='mailto:kingscotedoodles@gmail.com'>kingscotedoodles@gmail.com</a></p>

            <FormWrapper>
              <form name="contact"  netlify method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />

                <p>
                  <label>Your Name: <input type="text" name="name" /></label>
                </p>
                <p>
                  <label>Your Email: <input type="email" name="email" /></label>
                </p>

                <p>
                  <label>(Optional) Telephone:
                    <p>If you would like us to give you a call, please leave your telephone number</p>
                    <input type="text" name="phonenumber" /></label>
                </p>

                <p>
                  <label>Message: <textarea name="message"></textarea></label>
                </p>
                <p>
                  <button type="submit">Send Message</button>
                </p>
              </form>
            </FormWrapper>

        </main>
    </LayoutSimple>
)
export default SuccessPage